<template>
    <div class="page-wrapper" id="scroll_top">
        <section class="hero_full" v-if="pageIsLoaded">
            <div class="title_wrap" data-inview="fadeInUp" data-delay="200">
                <h1 class="title medium">{{ content.entete1.titre }}</h1>
                <p class="regular-text">
                    <span v-html="content.entete1.texte"></span>
                </p>
            </div>
            <div
                class="img direction"
                :style="'background-image:url(' + content.imageSimple1.url + ')'"
            >
                <a href="#next" v-scroll-to:-120 class="round">
                    <svg
                        fill="none"
                        class="arrow"
                        viewBox="0 0 13 26"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="m11.6412 17.9322-4.17083 4.2832v-22.2154h-1.94081v22.2154l-4.17082-4.2832-1.35874 1.395 6.5 6.6728 6.5-6.6728z"
                        />
                    </svg>
                </a>
            </div>
        </section>

        <section class="team_wrap" id="next" v-if="pageIsLoaded">
            <div
                class="team"
                data-inview="fadeInUp"
                data-delay="200"
                v-for="(item, i) in content.membreEquipe"
                :key="i"
            >
                <div class="img">
                    <a v-if="item.bio" href="#" @click.prevent="openBioPopup(item)"><img :src="item.image.url" :alt="item.image.titre" /></a>
                    <img v-else :src="item.image.url" :alt="item.image.titre" />
                </div>
                <div class="info">
                    <div class="left">
                        <h2 class="title">{{ item.titre }}</h2>
                        <p class="regular-text">{{ item.texte }}</p>
                    </div>
                    <a :href="item.lien" target="_blank" class="social">
                        <svg viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M14.5 0C6.49187 0 0 6.49187 0 14.5C0 22.5081 6.49187 29 14.5 29C22.5081 29 29 22.5081 29 14.5C29 6.49187 22.5081 0 14.5 0ZM6.96094 12.0098H10.247V21.8829H6.96094V12.0098ZM10.4634 8.95538C10.442 7.98733 9.74977 7.25 8.62564 7.25C7.50152 7.25 6.7666 7.98733 6.7666 8.95538C6.7666 9.90338 7.47979 10.6619 8.58299 10.6619H8.60399C9.74977 10.6619 10.4634 9.90338 10.4634 8.95538ZM18.3142 11.7778C20.4766 11.7778 22.0977 13.1893 22.0977 16.2218L22.0975 21.8828H18.8117V16.6007C18.8117 15.2739 18.3362 14.3686 17.1466 14.3686C16.2388 14.3686 15.698 14.9789 15.4605 15.5684C15.3737 15.7797 15.3523 16.074 15.3523 16.3691V21.8831H12.0659C12.0659 21.8831 12.1092 12.9364 12.0659 12.0099H15.3523V13.4084C15.7885 12.7361 16.5696 11.7778 18.3142 11.7778Z"
                            />
                        </svg>
                    </a>
                </div>
                <a v-if="item.bio" href="#" class="team-grid-item-text text" title="Bio" @click.prevent="openBioPopup(item)">
                    {{ $t('Biographie') }}
                    <svg width="13" height="14" viewbox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.618 0V13.156H5.2V0H7.618ZM12.792 5.408V7.722H0V5.408H12.792Z" fill="#0e0e0e"/>
                    </svg>
                </a>
            </div>
        </section>

        <RelatedAbout v-if="pageIsLoaded" :route="$route.path" />

		<div class="infos-popup-wrap" :class="{ 'is-active': bioIsOpen }">
			<div class="infos-popup-overlay" @click.prevent="closeBioPopup()"></div>
			<div class="infos-popup-content" v-if="bio">
				<a class="infos-popup-close" @click.prevent="closeBioPopup()">
					<svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M12.9992 9.94632L19.3679 3.63133C20.2107 2.78849 20.2107 1.47287 19.3679 0.632131C18.525 -0.21071 17.2094 -0.21071 16.3687 0.632131L10 6.94712L3.63133 0.632131C2.78849 -0.21071 1.47287 -0.157243 0.632131 0.632131C-0.21071 1.47497 -0.157243 2.79059 0.632131 3.63133L7.0008 9.94632L0.632131 16.2613C-0.21071 17.1042 -0.21071 18.4198 0.632131 19.2605C1.05354 19.6819 1.5798 19.8916 2.10605 19.8916C2.6323 19.8916 3.15855 19.6819 3.57997 19.2605L9.94864 12.9455L16.3173 19.2605C16.7387 19.6819 17.265 19.8916 17.7912 19.8916C18.3175 19.8916 18.8972 19.6819 19.2651 19.2605C20.108 18.4177 20.0545 17.102 19.2651 16.2613L12.9992 9.94632Z" fill="#0e0e0e"/>
					</svg>
				</a>
				<div class="inner">
                    <img :src="bio.image.url" :alt="bio.image.titre" data-popup-img>
                    <h3 class="infos-popup-item-title title" data-popup-title>
                        {{ bio.titre }}
                    </h3>
                    <h4 class="infos-popup-item-subtitle subtitle" data-popup-subtitle>
                        {{ bio.texte }}
                    </h4>
                    <p class="infos-popup-bio" data-popup-bio>
                        <span v-html="bio.bio"></span>
                    </p>
                </div>
			</div>
		</div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import GLightbox from 'glightbox'
// import Flickity from 'flickity'
import RelatedAbout from '@/components/RelatedAbout'

export default {
    data() {
        return {
            lightboxelement: null,
            bioIsOpen: false,
            bio: null,
        }
    },

    components: {
        RelatedAbout,
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        this.lightboxelement = GLightbox
                        GLightbox()

                        setTimeout(() => {
                            // this.teamCarousel.resize()
                        }, 1755)
                    }, 1055)
                }
            },
            immediate: true,
        },
    },

    computed: {
        servicesList() {
            if (!this.globalsIsLoaded) {
                return []
            }

            return this.globals.servicesEntry.data.slice(0, 4)
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        openBioPopup(bio) {
            this.bioIsOpen = true
            this.bio = bio
        },
        closeBioPopup() {
            this.bioIsOpen = false
            this.bio = null
        },
    },
}
</script>

<style lang="scss" scoped>

.team-grid-item-text {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 1;
    color: #0e0e0e;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid rgba(#0e0e0e, 0.5);
    border-bottom: 1px solid rgba(#0e0e0e, 0.5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.6s ease;

    &:hover {
        color: #000;
    }

    @media (max-width: 1023px) {
        font-size: 14px;
    }
}

.infos-popup-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: z-index 0.6s ease;

    &.is-active {
        z-index: 9999999999;

        .infos-popup-overlay {
            opacity: 1;
        }

        .infos-popup-content {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }
    }

    .infos-popup-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(0 0 0 / 60%);
        opacity: 0;
        transition: opacity 0.6s ease;
    }

    img {
        position: relative;
        overflow: hidden;
        display: block;
        width: 80px;
        height: 80px;
        min-width: 80px;
        min-height: 80px;
        max-width: 80px;
        max-height: 80px;
        border-radius: 50%;
        margin-bottom: 10px;
    }

    .infos-popup-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.8);
        width: 90%;
        max-width: 800px;
        background: #fff;
        padding: 60px;
        border-radius: 5px;
        opacity: 0;
        transition: opacity 0.6s ease,
            transform 0.6s ease;

        @media (max-width: 767px) {
            padding: 30px;
        }

        position: relative;

        .inner {
            position: relative;
            z-index: 2;
            max-height: 400px;
            overflow-y: auto;
        }

        &:before {
            content: '';
            position: absolute;
            top: 80%;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0.25) 0%,
                #fff 100%
            );
            z-index: 3;
        }

        .infos-popup-close {
            position: absolute;
            top: 40px;
            right: 40px;
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

        .infos-popup-item-title {
            font-style: normal;
            font-weight: 300;
            font-size: 30px;
            line-height: 1;
            padding-bottom: 20px;

            @media (max-width: 1279px) {
                font-size: 26px;
            }

            @media (max-width: 1023px) {
                font-size: 22px;
            }
        }

        .infos-popup-item-subtitle {
            font-style: normal;
            font-weight: 300;
            line-height: 1;
            padding-bottom: 20px;
        }

        .infos-popup-bio {
            font-weight: 300;
            font-size: 16px;
            line-height: 1.8;

            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
    }
}
</style>
